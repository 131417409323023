export const users = [
  {
    email: 'gmmartins06@gmail.com',
    role: 'ADMIN'
  },
  {
    email: 'adm@academiasymphonia.com',
    role: 'ADMIN'
  },
  {
    email: 'nobreflauta@gmail.com',
    role: 'ADMIN'
  },
  {
    email: 'anneaccarini@gmail.com',
    role: 'MARKETING'
  },
]