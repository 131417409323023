import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { v4 as uuidV4 } from 'uuid'
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';

import { styleModal } from 'components/Modal/style';
import { database } from 'config/firebase';
import { billsDescriptions } from 'mocks/bill';
import { handleMonetaryMask } from 'utils/mask';
import { useBills } from 'hooks/useBills';
import { months } from 'mocks/dates';
import { PieChart } from 'components/Charts';

const Expense = () => {
  const [loader, setLoader] = useState(true)
  const [showModalRegisterBill, setShowModalRegisterBill] = useState(false)
  const [month, setMonth] = useState(new Date().getMonth())
  const [year, setYear] = useState(new Date().getFullYear())
  const [description, setDescription] = useState('')
  const [date, setDate] = useState(new Date())
  const [amount, setAmount] = useState('')

  const useBillsState = useBills()
  const [bills, setBills] = useState<any[]>(Object.values(useBillsState))

  const filteredBills = bills.filter(d => d.date.getMonth() === +month && d.date.getFullYear() === +year)

  const chartPie = {
    labels: filteredBills.map(data => data.name).reduce((acc, crr) => {
      if (!acc.includes(crr)) {
        acc.push(crr)
      }

      return acc
    }, []),
    datasets: filteredBills.reduce((acc, crr) => {
      if (!acc.find((a: any) => a.description === crr.description)) {
        const filtered = filteredBills.filter(item => item.description === crr.description)
        const sum = filtered.reduce((acumulator, current) => acumulator + current.amount, 0)
        acc.push({ amount: sum, description: crr.description })
      }

      return acc
    }, []).map((a: any) => a.amount)
  }

  const chartGeneral = {
    labels: months,
    datasets: months.map((m, index) => {
      return bills
        .filter(d => d.date.getMonth() === index && d.date.getFullYear() === +year)
        .reduce((acc, crr) => {
          if (!acc.find((a: any) => a.description === crr.description)) {
            const filtered = filteredBills.filter(item => item.description === crr.description)
            const sum = filtered.reduce((acumulator, current) => acumulator + current.amount, 0)
            acc.push({ amount: sum, description: crr.description, ...crr })
          }

          return acc
        }, [])
    })
  }

  console.log(chartGeneral)

  const handleRegisterBill = async () => {
    const data = {
      description,
      date,
      amount: +amount,
      id: uuidV4(),
    }

    await database
      .collection('bills')
      .add(data)

    closeModalRegisterBill()
  }

  const closeModalRegisterBill = () => {
    setShowModalRegisterBill(false)

    setDescription('')
    setDate(new Date())
    setAmount('')
  }

  async function handleRemoveBill(id: string) {
    if (window.confirm('Você tem certeza que deseja excluir essa despesa ?')) {
      await database
        .collection('bills')
        .doc(id)
        .delete()

      setBills(bills.filter(b => b.firebaseId !== id))
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 100);
  }, [])

  if (loader) {
    return <h1>Carregando, aguarde...</h1>
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={2}>
        <Typography id="modal-modal-title" variant="h4" component="h1">
          Despesas
        </Typography>
      </Grid>
      <Grid item xs={12} md={2}>
        <FormControl variant="filled" fullWidth size="small">
          <InputLabel>Ano</InputLabel>
          <Select
            value={year}
            onChange={(text: any) => setYear(text.target.value)}
          >
            {
              ['2021', '2022'].map((currentYear: any) =>
                <MenuItem
                  key={currentYear}
                  value={currentYear}
                >
                  {currentYear}
                </MenuItem>
              )
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={2}>
        <FormControl variant="filled" fullWidth size="small">
          <InputLabel>Meses</InputLabel>
          <Select
            value={month}
            onChange={(text: any) => setMonth(text.target.value)}
          >
            {
              months.map((currentMonth: any, index) =>
                <MenuItem key={currentMonth} value={index}>
                  {currentMonth}
                </MenuItem>
              )
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4} />
      <Grid item xs={12} md={2}>
        <Button
          onClick={() => setShowModalRegisterBill(true)}
          variant="contained"
          sx={{ ml: 1 }}
          fullWidth
        >
          Cadastrar nova despesa
        </Button>
      </Grid>

      {/* Modal bill course */}
      <Modal
        open={showModalRegisterBill}
        onClose={closeModalRegisterBill}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styleModal, width: '30vw', }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={3}>
            Cadastre uma nova despesa
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <FormControl variant="filled" fullWidth>
              <InputLabel>Descrição da despesa</InputLabel>
              <Select
                value={description}
                onChange={(text: any) => setDescription(text.target.value)}
              >
                {
                  billsDescriptions
                    .map((bill: any) =>
                      <MenuItem
                        key={bill.id}
                        value={bill.id}
                      >
                        {bill.name}
                      </MenuItem>
                    )
                }
              </Select>
            </FormControl>

            <DatePicker
              label="Data da despesa"
              value={date}
              onChange={(newValue: any) => setDate(newValue)}
              renderInput={(params) => <TextField {...params} margin="normal" variant="filled" />}
            />

            <TextField
              label="Valor"
              value={amount}
              onChange={text => setAmount(text.target.value)}
              variant="filled"
            />

            <Button
              onClick={handleRegisterBill}
              variant="contained"
              sx={{ mt: 3 }}
            >
              Cadastrar despesa
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Table */}
      <Grid item xs={12} md={8}>
        <Paper sx={{ borderRadius: 2, p: 2, display: 'flex', flexDirection: 'column', height: '60vh', overflow: 'auto' }}>
          <Typography ml={1} fontWeight={700} component="h2" variant="h5" color="primary" gutterBottom>
            Lista de despesas
          </Typography>


          {filteredBills.length === 0
            ? (
              <Typography ml={1} mt={3} component="h2" variant="h5" color="primary" gutterBottom>
                Nenhuma despesa no mês de {months[month]}
              </Typography>
            ) : (
              <Table size="medium">
                <TableHead>
                  <TableRow>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredBills.map((bill) => (
                    <TableRow key={bill.id}>
                      <TableCell>{bill.name}</TableCell>
                      <TableCell>{format(bill.date, 'dd/MM/yyyy')}</TableCell>
                      <TableCell>{handleMonetaryMask(bill.amount.toString())}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() => handleRemoveBill(bill.firebaseId)}
                          variant="outlined"
                          size="small"
                          color="error"
                        >
                          Excluir
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
        </Paper>
      </Grid>

      {/* Dashboards */}
      <Grid item xs={12} md={4}>
        <Paper sx={{ p: 2, borderRadius: 2, height: '60vh' }}>

          <PieChart
            title="Resumo mensal das despesas"
            labels={chartPie.labels}
            datasets={chartPie.datasets}
          />
        </Paper>
      </Grid>
      {/* 
      <Grid item xs={12}>
        <Paper sx={{ p: 2, borderRadius: 2 }}>
          <Typography ml={1} mb={3} fontWeight={700} component="h2" variant="h5" color="primary" gutterBottom>
            Resumo anual das despesas
          </Typography>
          <Bar options={options} data={chartGeneral} />
        </Paper>
      </Grid> */}
    </Grid>
  )
}

export default Expense
