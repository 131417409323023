import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Grid, TextField, Typography } from '@mui/material'

import { database } from 'config/firebase'
import { convertTimestamp, sortByLargestDate } from 'utils/functions'

const Links = () => {

  const [instagram, setInstagram] = useState('')
  const [youtube, setYoutube] = useState('')
  const [firstData, setFirstData] = useState({
    instagram: '',
    youtube: ''
  })

  const [loading, setLoading] = useState(false)

  const handleDisableButton = instagram === firstData.instagram &&
    youtube === firstData.youtube

  async function handleAddConfigs() {
    setLoading(true)

    await database
      .collection('oacs-configurations')
      .add({
        instagram,
        youtube,
        createAt: new Date()
      })

    toast.success('Configurações cadastrada com sucesso')
    setLoading(false)
  }

  async function getConfigs() {
    await database
      .collection('oacs-configurations')
      .onSnapshot(query => {
        const response = query.docs.map((doc) => {
          return {
            ...doc.data(),
            firebaseId: doc.id,
            date: convertTimestamp(doc.data().createAt)
          }
        }) as any

        const [data] = sortByLargestDate(response)

        setFirstData({
          instagram: data.instagram,
          youtube: data.youtube
        })

        setInstagram(data.instagram)
        setYoutube(data.youtube)
      })
  }

  useEffect(() => {
    getConfigs()
  }, [])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} >
        <Typography id="modal-modal-title" variant="h4" component="h1">
          Configurações
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="URL Instagram"
          value={instagram}
          onChange={text => setInstagram(text.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="URL Youtube"
          value={youtube}
          onChange={text => setYoutube(text.target.value)}
          variant="filled"
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          onClick={handleAddConfigs}
          variant="contained"
          sx={{ mt: 3 }}
          disabled={loading || handleDisableButton}
        >
          Cadastrar configurações
        </Button>
      </Grid>
    </Grid>
  )
}

export default Links