import { ReactNode, useState } from "react";
import { Button, Paper, Skeleton, Table, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";

export type Event = {
  id: string;
  date: moment.Moment;
  description: string;
}

type CalendarProps = {
  date: moment.Moment;
  onChange: (date: moment.Moment) => void;
  onSelectedEvent: (event: Event) => void;
  children: ReactNode;
  events: Event[];
  loading: boolean
}

const Calendar: React.FC<CalendarProps> = ({
  date,
  onChange,
  onSelectedEvent,
  events,
  loading,
  children
}) => {
  const widthEvent = 100 / 7

  const [currentMonth, setCurrentMonth] = useState(date.month());
  const [currentYear, setCurrentYear] = useState(date.year());

  const currentDate = moment([currentYear, currentMonth]);

  const startOfMonth = currentDate.clone().startOf("month");
  const endOfMonth = currentDate.clone().endOf("month");

  const days = [];

  let day = startOfMonth.clone().subtract(startOfMonth.day(), 'day');

  while (day <= endOfMonth) {
    days.push(day.clone());
    day.add(1, "day");
  }

  if (days.length < 36) {
    const lenght = 36 - days.length
    for (let index = 1; index < lenght; index++) {
      days.push(endOfMonth.clone().add(index, 'day'))
    }
  }

  if (days.length >= 36 && days.length <= 43) {
    const lenght = 43 - days.length
    for (let index = 1; index < lenght; index++) {
      days.push(endOfMonth.clone().add(index, 'day'))
    }
  }

  const handleNextMonth = (next: boolean) => {
    if (next) {
      const nextYear = currentMonth + 1 === 12

      if (nextYear) {
        setCurrentMonth(0)
        setCurrentYear(currentYear + 1)
        onChange(moment([currentYear + 1, 1]))
        return
      }

      setCurrentMonth(currentMonth + 1)
      onChange(moment([currentYear, currentMonth + 1]))
      return
    }

    if (!currentMonth) {
      setCurrentMonth(11)
      setCurrentYear(currentYear - 1)
      onChange(moment([currentYear - 1, 11]))
      return
    }

    setCurrentMonth(currentMonth - 1)
    onChange(moment([currentYear, currentMonth - 1]))
  }

  const handleToday = () => {
    const today = moment()

    setCurrentMonth(today.month())
    setCurrentYear(today.year())
    onChange(today)
  }

  return (
    <Paper sx={{ borderRadius: 2, p: 2, display: 'flex', flexDirection: 'column', mt: 3 }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <Box display="flex" alignItems="center">
          <Button variant="contained" size="small" sx={{ mr: 3 }} onClick={handleToday}>
            Hoje
          </Button>
          <Button variant="contained" size="small" sx={{ mr: 3 }} onClick={() => handleNextMonth(false)}>
            {"<"}
          </Button>
          <Typography
            variant="body1"
            textTransform="capitalize"
            textAlign="center"
            sx={{ width: 120 }}
          >
            {currentDate.format("MMMM YYYY")}
          </Typography>
          <Button variant="contained" size="small" sx={{ ml: 3 }} onClick={() => handleNextMonth(true)}>
            {">"}
          </Button>
        </Box>
        {children}
      </Box>
      <Table>
        <Box display="flex">
          <Typography sx={{ width: `${widthEvent}%`, mb: 2 }} align="center">Dom</Typography>
          <Typography sx={{ width: `${widthEvent}%`, mb: 2 }} align="center">Seg</Typography>
          <Typography sx={{ width: `${widthEvent}%`, mb: 2 }} align="center">Ter</Typography>
          <Typography sx={{ width: `${widthEvent}%`, mb: 2 }} align="center">Qua</Typography>
          <Typography sx={{ width: `${widthEvent}%`, mb: 2 }} align="center">Qui</Typography>
          <Typography sx={{ width: `${widthEvent}%`, mb: 2 }} align="center">Sex</Typography>
          <Typography sx={{ width: `${widthEvent}%`, mb: 2 }} align="center">Sab</Typography>
        </Box>
        <Box display="flex" flexWrap="wrap">
          {days.map(day => {
            return (
              <Box
                key={day.toString()}
                sx={{
                  width: `${widthEvent}%`,
                  border: '2px solid #ccc',
                  height: 120,
                  marginTop: '-2px',
                  marginLeft: '-2px',
                  opacity: day.month() === currentMonth ? 1 : .3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  flexDirection: 'column',
                  px: 2,
                }}
              >
                <Typography
                  textAlign="center"
                  my={1}
                  borderRadius={2.5}
                  width="min-content"
                  px={1}
                  bgcolor={day.format('DDMMYYYY') === moment().format('DDMMYYYY') ? 'primary.main' : 'transparent'}
                  color={day.format('DDMMYYYY') === moment().format('DDMMYYYY') ? 'white' : 'primary.main'}
                >
                  {day.format("DD")}
                </Typography>

                {
                  !loading ? events
                    .sort((a, b) => (moment(a.date, "DDMMYYYYHHmm").diff(moment(b.date, "DDMMYYYYHHmm"))))
                    .map((event) => {
                      const eventIsToday = day.format('DDMMYYYY') === event.date.format('DDMMYYYY')

                      if (!eventIsToday) return <></>

                      return (
                        <Box
                          onClick={() => onSelectedEvent(event)}
                          sx={{
                            cursor: 'pointer',
                            borderRadius: 1,
                            bgcolor: 'lightblue',
                            px: 1,
                            my: .4,
                            width: '100%',
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 12,
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }}
                          >
                            {event.date.format('HH:mm')} | {event.description}
                          </Typography>
                        </Box>
                      )
                    }) : (
                    <>
                      <Skeleton variant="rectangular" sx={{ mb: 1, width: '100%', height: 20 }} />
                      <Skeleton variant="rectangular" sx={{ mb: 1, width: '100%', height: 20 }} />
                      <Skeleton variant="rectangular" sx={{ mb: 1, width: '100%', height: 20 }} />
                    </>
                  )
                }
              </Box>
            );
          })}
        </Box>
      </Table>
    </Paper >
  );
};

export default Calendar;
