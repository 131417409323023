import React, { useEffect, useState } from 'react'
import {
  Box,
  colors,
  Grid,
  Paper,
  Typography
} from '@mui/material'
import { Student, useStudents } from 'hooks/useStudents';
import { handleMonetaryMask } from 'utils/mask';
import { Bill, useBills } from 'hooks/useBills';

const Dashboard = () => {
  const [loader, setLoader] = useState(true)

  const [students, setStudents] = useState<Student[]>([])
  const [bills, setBills] = useState<Bill[]>([])

  const useStudentsState = useStudents()
  const useBillsState = useBills()

  useEffect(() => {
    setStudents(Object.values(useStudentsState))
    setBills(Object.values(useBillsState))
  }, [useBillsState, useStudentsState])

  const totalRenenue = students
    .filter(s => s.status === 'active')
    .reduce((acc, current) => acc + current.revenue, 0)

  const total = students
    .filter(s => s.status === 'active')
    .reduce((acc, current) => acc + current.amount, 0)

  const totalBills = bills.reduce((acc, current) => acc + current.amount, 0)

  const totalWithdraw = total - totalRenenue

  const balance = totalRenenue - totalBills - totalWithdraw

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 1000);
  }, [])

  if (loader) {
    return <h1>Montando o dashboard, aguarde...</h1>
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={10}>
        <Typography id="modal-modal-title" variant="h4" component="h1">
          Dashboard geral
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Paper
          sx={{
            borderRadius: 2,
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
          }}
        >
          <Box display="block" mb={3}>
            <Typography fontWeight={700} component="h2" variant="h6" color="primary" gutterBottom>
              Entradas
            </Typography>
            <Typography component="p" variant="h4" sx={{ color: total > 0 ? colors.green[500] : colors.red[500] }}>
              {handleMonetaryMask(total.toString())}
            </Typography>
          </Box>

          <Box display="block" mb={3}>
            <Typography fontWeight={700} component="h2" variant="h6" color="primary" gutterBottom>
              Lucro das aulas
            </Typography>
            <Typography component="p" variant="h4" sx={{ color: totalRenenue > 0 ? colors.green[500] : colors.red[500] }}>
              {handleMonetaryMask(totalRenenue.toString())}
            </Typography>
          </Box>

          <Box display="block" mb={3}>
            <Typography fontWeight={700} component="h2" variant="h6" color="primary" gutterBottom>
              Pagamento dos professores
            </Typography>
            <Typography component="p" variant="h4" sx={{ color: colors.red[500] }}>
              {handleMonetaryMask(totalWithdraw.toString())}
            </Typography>
          </Box>

          <Box display="block" mb={3}>
            <Typography fontWeight={700} component="h2" variant="h6" color="primary" gutterBottom>
              Despesas (contas)
            </Typography>
            <Typography component="p" variant="h4" sx={{ color: colors.red[500] }}>
              {handleMonetaryMask(totalBills.toString())}
            </Typography>
          </Box>

          <Box display="block" mb={3} sx={{ color: balance > 0 ? colors.green[500] : colors.red[500] }}>
            <Typography fontWeight={700} component="h2" variant="h6" gutterBottom>
              Balanço geral
            </Typography>
            <Typography component="p" variant="h4">
              {handleMonetaryMask(balance.toString())}
            </Typography>
          </Box>
        </Paper>
      </Grid>

    </Grid>
  )
}

export default Dashboard
