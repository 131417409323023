import create from 'zustand'

export interface Student {
  amount: number;
  revenue: number;
  amountMask: string;
  course: string;
  firebaseId: string;
  id: string;
  name: string;
  status: string;
  createdAt: Date;
  contracts: Contract[];
  outdatedContract: boolean;
  teacher: string;
  email: string;
}

type Contract = {
  createdAt: Date;
  local: string;
  src: string;
  expire: string;
}

export const useStudents = create<Student[]>(() => ([]))
