import React from 'react'
import { ToastContainer } from 'react-toastify'
import { ThemeProvider } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR'


import 'react-toastify/dist/ReactToastify.css'

import { theme } from 'styles/theme'
import Routes from './routes'
import "filepond/dist/filepond.min.css";

import {Chart, ArcElement} from 'chart.js'
Chart.register(ArcElement);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ptBR}
      >
        <CssBaseline />
        <Routes />
        <ToastContainer />
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
