import React from 'react'
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate
} from 'react-router-dom'

import { useUserData } from 'hooks/useUserData'

import Login from 'pages/Common/Login'
import NotFound from 'pages/Common/NotFound'

// GESTÃO FINANCEIRA
import Dashboard from 'pages/Auth/FinancialManagement'
import DashboardStudents from 'pages/Auth/FinancialManagement/Students'
import DashboardCourses from 'pages/Auth/FinancialManagement/Courses'
import DashboardTeachers from 'pages/Auth/FinancialManagement/Teachers'
import Expense from 'pages/Auth/FinancialManagement/Expense'

// GESTÃO ACADEMICA
import Teachers from 'pages/Auth/AcademyManagement/Teachers'
import Students from 'pages/Auth/AcademyManagement/Students'
import Calendar from 'pages/Auth/AcademyManagement/Calendar'
import Courses from 'pages/Auth/AcademyManagement/Courses'

// OACS
import Partners from 'pages/Auth/OACS/Partners'
import Links from 'pages/Auth/OACS/Links'
import Members from 'pages/Auth/OACS/Members'
import Events from 'pages/Auth/OACS/Events'

// SITE
import Testimonies from 'pages/Auth/Site/Testimonies'
import Projects from 'pages/Auth/Site/Projects'
import Configs from 'pages/Auth/Site/Configs'

import AuthLayout from 'layouts/Auth'
import { AuthContextProvider } from 'contexts/auth'
import StudentDetails from 'pages/Auth/AcademyManagement/Students/[id]'


function RequireAuth({ children }: any) {
  const { logged } = useUserData()
  if (!logged) {
    return <Navigate to="/" />
  }

  return children
}

const Router = () => (
  <BrowserRouter>
    <AuthContextProvider>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          element={
            <RequireAuth>
              <AuthLayout />
            </RequireAuth>
          }
        >

          {/* Financial Management */}
          <Route path="/dashboard-students" element={<DashboardStudents />} />
          <Route path="/dashboard-courses" element={<DashboardCourses />} />
          <Route path="/dashboard-teachers" element={<DashboardTeachers />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/expense" element={<Expense />} />

          {/* Academy Management */}
          <Route path="/teachers" element={<Teachers />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/students" element={<Students />} />
          <Route path="/students/:id" element={<StudentDetails />} />
          <Route path="/calendar" element={<Calendar />} />

          {/* Site */}
          <Route path="/configs" element={<Configs />} />
          <Route path="/testimony" element={<Testimonies />} />
          <Route path="/projects" element={<Projects />} />

          {/* OACS */}
          <Route path="/oacs/partners" element={<Partners />} />
          <Route path="/oacs/live" element={<Links />} />
          <Route path="/oacs/members" element={<Members />} />
          <Route path="/oacs/events" element={<Events />} />

          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="*" element={<Login />} />
      </Routes>
    </AuthContextProvider>
  </BrowserRouter>
)

export default Router
