type FirebaseType = {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

type InitialConfigType = {
  version: string;
  firebaseConfig: {
    hml: FirebaseType,
    prod: FirebaseType
  }
}

const environment: 'prod' | 'hml' = "prod"

export const initialConfig: InitialConfigType = {
  version: "1.0.0",
  firebaseConfig: {
    hml: {
      apiKey: "AIzaSyCUKdD9TiLRR1_LO9Bv1jmdy29ifYyrJH0",
      authDomain: "symphonia-hml.firebaseapp.com",
      projectId: "symphonia-hml",
      storageBucket: "symphonia-hml.appspot.com",
      messagingSenderId: "646743931863",
      appId: "1:646743931863:web:c14901e8dcf81bcae1e992",
      measurementId: "G-P0NNMWPWFV"
    },
    prod: {
      apiKey: "AIzaSyBHNA4mG3pycdgruFvdfFUwRKRKf9xVBtE",
      authDomain: "symphonia-d915c.firebaseapp.com",
      projectId: "symphonia-d915c",
      storageBucket: "symphonia-d915c.appspot.com",
      messagingSenderId: "932620433534",
      appId: "1:932620433534:web:318f039ee1516146c8402f",
      measurementId: "G-6YDF1KD3MK"
    },
  }
}

export const firebaseConfig = initialConfig.firebaseConfig[environment]
