
import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  colors,
  FormControl,
  Grid,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { v4 as uuidV4 } from 'uuid'

import { styleModal } from 'components/Modal/style'
import { database } from 'config/firebase'
import { Teacher, useTeachers } from 'hooks/useTeachers'
import { useCourses } from 'hooks/useCourses'
import { handleMonetaryMask, maskOnlyNumbers, maskPhoneNumber } from 'utils/mask'
import { weekdays } from 'mocks/dates'
import { TimePicker } from '@mui/x-date-pickers'
import { getHoursArray, isMobile } from 'utils/functions'
import { toast } from 'react-toastify'
import moment from 'moment'

const Teachers = () => {
  const [openModalNewTeacher, setOpenModalNewTeacher] = useState(false)
  const [openModalSuccess, setopenModalSuccess] = useState(false)

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [course, setCourse] = useState<string[]>([]);
  const [amount, setAmount] = useState('')
  const [email, setEmail] = useState('')
  const [codeUser, setCodeUser] = useState('')
  const [phone, setPhone] = useState('')
  const [bank, setBank] = useState('')
  const [firebaseId, setFirebaseId] = useState('')

  const usTeachersContext = useTeachers()
  const useCoursesState = useCourses()

  const courses = Object.values(useCoursesState)
  const teachers = Object.values(usTeachersContext)

  const [availableTimes, setAvailableTimes] = useState<any[]>([])

  const columns = [
    {
      id: 1,
      label: 'Nome Completo',
    },
    {
      id: 3,
      label: 'Valor da aula',
    },
    {
      id: 4,
      label: 'Cursos',
    },
    {
      id: 5,
      label: 'Status',
    },
    {
      id: 6,
      label: 'Status de acesso no app',
    },
    {
      id: 7,
      label: 'Ações',
    },
  ]

  const handleAddTeacher = async () => {

    setLoading(true)

    const availableSchedule = availableTimes.map(times => {
      const { weekday, start, end } = times

      const initialHours = getHoursArray(start, end)
      const hours = initialHours.map(hour => {
        const nextHour = moment(hour, 'HH:mm').add(1, 'h').format('HH:mm')

        return `${hour} - ${nextHour}`
      })

      return {
        weekday,
        hours,
      }
    })

    if (firebaseId.length < 1) {

      const code = Math.random().toString(36).slice(-6)
      setCodeUser(code)

      await database
        .collection('teachers')
        .add({
          name,
          courses: course.map(crrCourse => {
            return courses.filter(crr => crr.name === crrCourse)[0].id
          }),
          amount: maskOnlyNumbers(amount.toString()),
          id: uuidV4(),
          status: 'active',
          email,
          isFirstAccess: true,
          code,
          bank,
          phone,
          availableTimes: availableSchedule
        })
      setLoading(false)
      closeModal()
      setopenModalSuccess(true)
    } else {

      await database
        .collection('teachers')
        .doc(firebaseId)
        .update({
          name,
          courses: course.map(crrCourse => {
            return courses.filter(crr => crr.name === crrCourse)[0].id
          }),
          amount: maskOnlyNumbers(amount.toString()),
          email,
          bank,
          phone,
          availableTimes: availableSchedule
        })
      setLoading(false)
      closeModal()
    }

  }

  const closeModal = () => {
    setOpenModalNewTeacher(false)
    setName('')
    setCourse([])
    setAmount('')
    setEmail('')
    setPhone('')
    setBank('')
    setFirebaseId('')
    setAvailableTimes([])
  }

  const closeModalSuccess = () => {
    setopenModalSuccess(false)
    setCodeUser('')
  }

  const changeStatusTeacher = async (id: string, status: string) => {
    if (window.confirm(`Você tem certeza que deseja ${status === 'active' ? 'Ativar' : 'Desativar'} esse professor ?`)) {
      await database
        .collection('teachers')
        .doc(id)
        .update({ status })
    }
  }

  const editTeacher = (row: any) => {
    setOpenModalNewTeacher(true)
    setName(row.name)
    setCourse(row.coursesName)
    setAmount(row.amount)
    setEmail(row.email)
    setPhone(row.phone)
    setBank(row.bank)
    setFirebaseId(row.firebaseId)

    const times = row.availableTimes ? row.availableTimes.map(({ weekday, hours }: any) => {
      const [start] = hours[0].split('-')
      const end = hours[hours.length - 1].split('-')[1]

      return {
        weekday,
        start: moment(start, 'HH:mm'),
        end: moment(end, 'HH:mm'),
      }
    }) : []
    setAvailableTimes(times)
  }

  const handleChangeCourse = (event: any) => {
    const {
      target: { value },
    } = event;

    setCourse(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const Rows = teachers.map(t => {
    return {
      ...t,
      status: t.status === 'active' ? 'Ativo' : 'Inativo',
      statusApp: !t.isFirstAccess ? 'Acesso realizado' : 'Não acessado',
      statusAppColor: !t.isFirstAccess ? colors.green[500] : colors.red[500],
      statusColor: t.status === 'active' ? colors.green[500] : colors.red[500],
      coursesName: t.courses.map(c => { return courses.filter(crr => crr.id === c)[0].name })
    }
  })

  const addRow = () => {
    setAvailableTimes([{
      weekday: '',
      start: null,
      end: null
    }, ...availableTimes])
  }

  const handleChangeWeekdayAvailableTime = (value: string, index: number) => {
    availableTimes[index] = {
      weekday: value,
      start: availableTimes[index].start,
      end: availableTimes[index].end
    }
    setAvailableTimes([...availableTimes])
  }

  const handleChangeStartTimeAvailableTime = (value: string, index: number) => {
    availableTimes[index] = {
      start: value,
      weekday: availableTimes[index].weekday,
      end: availableTimes[index].end
    }
    setAvailableTimes([...availableTimes])
  }

  const handleChangeEndTimeAvailableTime = (value: string, index: number) => {
    availableTimes[index] = {
      end: value,
      weekday: availableTimes[index].weekday,
      start: availableTimes[index].start
    }
    setAvailableTimes([...availableTimes])
  }

  const copyCode = () => {
    navigator.clipboard.writeText(codeUser)
    toast.success('Código copiado com sucesso!')
    setopenModalSuccess(false)
  }

  const handleRegisterNewTeacher = () => {
    if (courses.length === 0) {
      toast.error('Para realizar o cadastro de um novo aluno é obrigatório ter pelo menos um curso cadastrado')
      return
    }

    setOpenModalNewTeacher(true)
  }

  const handleCopiedCode = (row: Teacher) => {
    if (!row.isFirstAccess) {
      toast.error('Não é possível copiar esse código pois o usuário já está com a conta habilitada no aplicativo.')
      return
    }

    setCodeUser(row.code)
    setopenModalSuccess(true)
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography id="modal-modal-title" variant="h4" component="h1">
          Todos os Professores
        </Typography>
        <Button
          onClick={handleRegisterNewTeacher}
          variant="contained"
        >
          Cadastrar novo professor
        </Button>
      </div>

      {/* Modal Add teacher */}
      <Modal
        open={openModalNewTeacher}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styleModal, width: isMobile() ? '80vw' : '50vw', }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {!!firebaseId
                  ? `Editar dados do prefessor ${name.toLocaleLowerCase()}`
                  : 'Cadastre um novo professor'
                }
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Nome do professor(a)"
                value={name}
                onChange={text => setName(text.target.value)}
                variant="filled"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="E-mail para contato"
                value={email}
                onChange={text => setEmail(text.target.value)}
                variant="filled"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Celular/WhatsApp"
                value={maskPhoneNumber(phone)}
                onChange={text => setPhone(text.target.value)}
                variant="filled"
                fullWidth
                inputProps={{
                  maxLength: 15
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Dados Bancários"
                value={bank}
                onChange={text => setBank(text.target.value)}
                variant="filled"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Cursos</InputLabel>
                <Select
                  multiple
                  value={course}
                  onChange={handleChangeCourse}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {courses.map(({ name, id }) => (
                    <MenuItem key={id} value={name}>
                      <Checkbox checked={course.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Valor da aula"
                value={handleMonetaryMask(amount.toString())}
                onChange={text => setAmount(text.target.value)}
                variant="filled"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Link underline="hover" onClick={addRow} variant="inherit" component="span">
                + Incluir novo horário
              </Link>
            </Grid>

            {
              availableTimes.map((available, index) => (
                <>
                  <Grid item xs={12} md={6}>
                    <FormControl variant="filled" fullWidth>
                      <InputLabel>Dia da semana</InputLabel>
                      <Select
                        value={available.weekday}
                        onChange={event => handleChangeWeekdayAvailableTime(event.target.value, index)}
                        renderValue={(selected) => selected}
                      >
                        {weekdays.map((day, index) => (
                          <MenuItem key={index} value={day}>
                            <ListItemText primary={day} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TimePicker
                      label="Horário Inicial"
                      value={available.start}
                      onChange={text => handleChangeStartTimeAvailableTime(text, index)}
                      renderInput={(params) => <TextField {...params} variant="filled" fullWidth />}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TimePicker
                      label="Horário Final"
                      value={available.end}
                      onChange={text => handleChangeEndTimeAvailableTime(text, index)}
                      renderInput={(params) => <TextField {...params} variant="filled" fullWidth />}
                    />
                  </Grid>
                </>
              ))
            }

            <Grid item xs={12}>
              <Button
                onClick={handleAddTeacher}
                variant="contained"
                disabled={loading}
              >
                {loading ? 'Carregando...' : !!firebaseId ? 'Editar' : 'Cadastrar'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        open={openModalSuccess}
        onClose={closeModalSuccess}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styleModal, width: '30vw', }}>
          <Typography id="modal-modal-title" variant="h6" component="h1" mb={3}>
            Sucesso
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h5" mb={3}>
            Envie o código <b>{codeUser}</b> para o professor conseguir acessar o aplicativo
          </Typography>
          <Button
            onClick={copyCode}
            variant="contained"
            color="info"
          >
            Copiar codigo
          </Button>
        </Box>
      </Modal>

      {
        teachers.length === 0 ? (
          <Typography sx={{ mt: 4 }} variant="h5" component="h5">
            Não temos professores cadastrados
          </Typography>
        ) : (
          <Paper sx={{ borderRadius: 2, p: 2, display: 'flex', flexDirection: 'column', mt: 3 }}>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) =>
                    <TableCell
                      align={index === 0 ? 'inherit' : 'right'}
                      key={column.id}
                    >
                      {column.label}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {Rows.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">{row.name}</TableCell>
                    <TableCell align="right">{row.amountMask}</TableCell>
                    <TableCell align="right">{row.coursesName.join(', ')}</TableCell>
                    <TableCell align="right" sx={{ color: row.statusColor }}>{row.status}</TableCell>
                    <TableCell align="right" sx={{ color: row.statusAppColor }}>{row.statusApp}</TableCell>
                    <TableCell align="right">
                      <Button
                        variant="outlined"
                        size="small"
                        color={row.status === 'Ativo' ? 'error' : 'success'}
                        onClick={() => changeStatusTeacher(row.firebaseId, row.status === 'Ativo' ? 'inactive' : 'active')}
                      >
                        {row.status === 'Ativo' ? 'Desativar' : 'Ativar'}
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        color="info"
                        onClick={() => editTeacher(row)}
                        sx={{ mx: 1 }}
                      >
                        Editar
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => alert('Função em desenvolvimento')}
                      >
                        Detalhes
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        color="success"
                        onClick={() => handleCopiedCode(row)}
                        sx={{ ml: 1 }}
                      >
                        Código
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )
      }

    </>
  )
}

export default Teachers
