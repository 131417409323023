import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Button,
  Grid,
  Modal,
  TextField,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';

import { database } from 'config/firebase'
import Calendar, { Event } from 'components/Calendar'
import { Classes as ClassesType } from 'hooks/useClasses'
import { styleModal } from 'components/Modal/style'
import { isMobile } from 'utils/functions'
import { DateTimePicker } from '@mui/x-date-pickers'
import { useTeachers } from 'hooks/useTeachers'
import { useStudents } from 'hooks/useStudents'
import { weekdays } from 'mocks/dates'
import { toast } from 'react-toastify';
import { useCourses } from 'hooks/useCourses';

const Classes = () => {

  const [currentDate, setCurrentDate] = useState(moment())

  const useTeachersContext = useTeachers()
  const useStudentsState = useStudents()
  const useCoursesState = useCourses()

  const teachers = Object.values(useTeachersContext)
  const students = Object.values(useStudentsState)
  const courses = Object.values(useCoursesState)

  const [loader, setLoader] = useState(false)
  const [events, setEvents] = useState<Event[]>([])
  const [allClasses, setAllClasses] = useState<ClassesType[]>([])
  const [selectedClass, setSelectedClass] = useState<ClassesType | null>(null)

  const [date, setDate] = useState(moment())
  const [student, setStudent] = useState<string | null>(null)
  const [teacher, setTeacher] = useState<string | null>(null)

  const [openModalAddClass, setOpenModalAddClass] = useState(false)

  async function getClasses() {
    setLoader(true)
    const response = await database
      .collection('classes')
      .get()

    const allClasses = response.docs.map((doc) => {
      return {
        ...doc.data(),
        date: moment(doc.data().date, 'DD/MM/YYYY HH:mm'),
        firebaseId: doc.id,
      }
    }) as ClassesType[]

    setAllClasses(allClasses)

    const classesFormatted = allClasses.map(classe => {
      const { date, student, id } = classe
      const course = courses.find(c => c.id === student.course)

      const description = `${course?.name.split(' ')[0]} | ${student.name}`

      return {
        id,
        date,
        description: description
      }
    })

    setEvents(classesFormatted)

    setTimeout(() => {
      setLoader(false)
    }, 1000);
  }

  const onSelectedEvent = (event: Event) => {
    const selectedClass = allClasses.find(classe => classe.id === event.id)

    if (selectedClass) {
      setSelectedClass(selectedClass)

      setDate(selectedClass.date)
      setStudent(selectedClass.studentId)
      setTeacher(selectedClass.teacherId)
    }

  }

  const closeModal = () => {
    setOpenModalAddClass(false)
    setDate(moment())
    setStudent(null)
    setTeacher(null)
    setSelectedClass(null)
  }

  const closeModalDetails = () => {
    setSelectedClass(null)
  }

  const handleAddClass = async () => {

    setLoader(true)
    const existsClassInDaySelected = allClasses
      .filter(c => c.teacherId === teacher)
      .filter(c => c.date.format('DDMMYYYYHHmm') === date.clone().format('DDMMYYYYHHmm'))

    if (existsClassInDaySelected.length > 0) {
      toast.error('Já existe uma aula cadastrada nesse horarário')
      setLoader(false)
      return
    }

    if (!!selectedClass) {
      await database
        .collection('classes')
        .doc(selectedClass.firebaseId)
        .update({
          date: date.format('DD/MM/YYYY HH:mm')
        })
    }

    getClasses()
    closeModal()
    setLoader(false)
    return
  }

  const classTitle = `${weekdays[selectedClass?.date.weekday() || 1]}, ${selectedClass?.date.format('DD/MM/YY,  HH:mm')} - ${selectedClass?.date.clone().add(1, 'h').format('HH:mm')}`

  useEffect(() => {
    getClasses()
  }, [])

  return (
    <>
      {/* Modal Add class */}
      <Modal
        open={openModalAddClass}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styleModal, width: isMobile() ? '80vw' : '30vw', }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {!!selectedClass ? 'Editar aula' : 'Cadastre uma nova aula'}
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <DateTimePicker
                label="Data"
                value={date}
                onChange={text => setDate(moment(text))}
                renderInput={(params) => <TextField {...params} variant="filled" fullWidth />}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Aluno</InputLabel>
                <Select
                  value={student}
                  onChange={(text: any) => setStudent(text.target.value)}
                  disabled={!!selectedClass}
                >
                  {
                    students
                      .filter(currentStudent => currentStudent.status === 'active')
                      .map((currentStudent: any) =>
                        <MenuItem
                          key={currentStudent.id}
                          value={currentStudent.id}
                        >
                          {currentStudent.name}
                        </MenuItem>
                      )
                  }
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Professor</InputLabel>
                <Select
                  value={teacher}
                  onChange={(text: any) => setTeacher(text.target.value)}
                  disabled={!!selectedClass}
                >
                  {
                    teachers
                      .filter(currentTeacher => currentTeacher.status === 'active')
                      .map((currentTeacher: any) =>
                        <MenuItem
                          key={currentTeacher.id}
                          value={currentTeacher.id}
                        >
                          {currentTeacher.name}
                        </MenuItem>
                      )
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Button
            onClick={handleAddClass}
            variant="contained"
            sx={{ mt: 3 }}
            disabled={loader || !teacher || !student}
          >
            {!!selectedClass ? 'Editar aula' : 'Adicionar aula'}
          </Button>
        </Box>
      </Modal >

      {/* Modal details class */}
      <Modal
        open={!!selectedClass}
        onClose={closeModalDetails}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styleModal, width: isMobile() ? '80vw' : '30vw', }}>
          <Grid container spacing={3}>
            <Grid item xs={11}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {classTitle}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <div style={{ cursor: 'pointer' }} onClick={() => setOpenModalAddClass(true)}>
                <EditIcon />
              </div>
            </Grid>

          </Grid>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              onClick={() => { }}
              variant="contained"
              color="info"
              sx={{ mt: 3 }}
            >
              Entregar aula
            </Button>
            <Button
              onClick={() => { }}
              variant="contained"
              color="error"
              sx={{ mt: 3 }}
            >
              Cancelar aula
            </Button>
          </Box>
        </Box>
      </Modal >

      <Calendar
        date={currentDate}
        onChange={date => setCurrentDate(date)}
        events={events}
        loading={loader}
        onSelectedEvent={event => onSelectedEvent(event)}
      >
        <Button
          onClick={() => setOpenModalAddClass(true)}
          variant="contained"
        >
          Cadastrar nova aula
        </Button>
      </Calendar>
    </>
  )
}

export default Classes