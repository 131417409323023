import create from 'zustand'

export interface Bill {
  firebaseId: string;
  id: number;
  name: string;
  description: string;
  date: Date
  amount: number
  month: string
}

export const useBills = create<Bill[]>(() => ([]))
