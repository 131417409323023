import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

import {
  Typography,
  Box,
  Paper,
  Button
} from '@mui/material'

import { useUserData } from 'hooks/useUserData'
import { useMenu } from 'hooks/useMenu'

import background from 'assets/background.jpeg'
import { useAuth } from 'hooks/useAuth'

const Login = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const { signInWithGoogle } = useAuth()

  const { logged } = useUserData()
  const { path } = useMenu()

  async function handleLogin() {
    if (!logged) {
      const response = await signInWithGoogle()
      setLoading(false)
      if (!response) {
        setLoading(false)
        return toast.error('Usário não tem permissão para acessar esse sistema. Por favor procure o administrador')
      } else return navigate(response.children[0].path)

    } else return navigate(path as string)
  }

  return (
    <Box
      component="main"
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${background})`
      }}
    >

      <Box
        sx={{
          py: 8,
          px: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: 2
        }}
        component={Paper}
        elevation={6}
        square
      >

        <Typography component="h1" variant="h5">
          Academia Symphonia - Acesso Restrito
        </Typography>

        <Box
          sx={{
            display: 'flex',
            width: '100%',
            mt: 3,
          }}
        >

          <Button
            onClick={handleLogin}
            variant="contained"
            fullWidth
            disabled={loading}
          >
            Acessar
          </Button>
        </Box>

      </Box>

    </Box>
  )
}

export default Login
