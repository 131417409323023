import create from 'zustand'

export interface Config {
  firebaseId: string;
  instagram: string;
  facebook: string;
  phone: string;
  email: string;
  maps: string
}

export const useConfigs = create<Config>(() => ({
  firebaseId: '',
  instagram: '',
  facebook: '',
  phone: '',
  email: '',
  maps: ''
}))
