import { createTheme } from '@mui/material'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1C232D',
      50: '#F7F8F9',
      100: '#EEF0F3',
      200: '#D5DAE1',
      300: '#BBC3CF',
      400: '#8896AB',
      500: '#556987',
      600: '#4D5F7A',
      700: '#404F65',
      800: '#333F51',
      900: '#2A3342',
    },
    secondary: {
      main: '#CA9F6A',
      50: '#EAD8C3',
      100: '#DFC4A4',
      200: '#D5B186',
      300: '#CFA877',
      400: '#CFA877',
      500: '#CA9F6A',
      600: '#BF8A4A',
      700: '#B58040',
      800: '#976B35',
      900: '#79562A',
    }
  }
})
