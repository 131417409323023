export function maskOnlyNumbers(numbers: string) {
  numbers = numbers.replace(/[^0-9]/g, '');
  return numbers;
}

export function maskPhoneNumber(phone: string, internationalNumber = false) {
  phone = phone.replace(/\D/g, '');

  if (internationalNumber) {
    const block = [];
    block[0] = phone.substr(0, 2);
    block[1] = phone.substr(2, 2);

    if (phone.length >= 13) {
      block[2] = phone.substr(4, 5);
      block[3] = phone.substr(9, 13);
    } else {
      block[2] = phone.substr(4, 4);
      block[3] = phone.substr(8, 12);
    }

    let formattedPhone = '';
    if (block[0] && block[0].length > 0) {
      formattedPhone += '+';
    }
    if (block[0]) {
      formattedPhone += `${block[0]}`;
    }
    if (block[1] && block[1].length > 0) {
      formattedPhone += ` (${block[1]}`;
    }
    if (block[1] && block[1].length > 1) {
      formattedPhone += ')';
    }
    if (block[2] && block[2].length > 0) {
      formattedPhone += ` ${block[2]}`;
    }
    if (block[2] && block[2].length > 3) {
      formattedPhone += '-';
    }
    if (block[3] && block[3].length > 0) {
      formattedPhone += `${block[3]}`;
    }
    return formattedPhone;
  }

  phone = phone.replace(/(\d{2})(\d)/, '($1) $2');
  phone = phone.replace(/(\d{5})(\d{1,4})$/, '$1-$2');
  return phone;
}

export function handleMonetaryMask(value: any, prefix = 'R$ ') {
  if (!value) {
    return `${prefix}0,00`;
  }

  let monetary = value.replace(/\D/g, '');
  monetary = (monetary / 100).toFixed(2) + '';
  monetary = monetary.replace('.', ',');
  monetary = monetary.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
  monetary = monetary.replace(/(\d)(\d{3}),/g, '$1.$2,');
  monetary = prefix + monetary;
  return monetary;
}


export function maskDate(text: string = '') {
  text = text.replace(/[^0-9]/g, '');

  const block = [];
  block[0] = text.substr(0, 2);
  block[1] = text.substr(2, 2);
  block[2] = text.substr(4);

  let formattedDate = '';
  if (block[0] && block[0].length > 0) {
    formattedDate += `${block[0]}`;
  }
  if (block[0].length > 1 && block[1].length > 0) {
    formattedDate += `/${block[1]}`;
  }
  if (block[1].length > 1 && block[2].length > 0) {
    formattedDate += `/${block[2]}`;
  }

  return formattedDate;
}