import React, { useState } from 'react'
import {
  Box, Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { v4 as uuidV4 } from 'uuid'

import { styleModal } from 'components/Modal/style'
import { database } from 'config/firebase'
import { useCourses } from 'hooks/useCourses'
import { isMobile } from 'utils/functions'

const Courses = () => {
  const [openModalNewCourse, setOpenModalNewCourse] = useState(false)
  const [nameOfCourse, setNameOfCourse] = useState('')

  const useCoursesState = useCourses()
  const courses = Object.values(useCoursesState)

  const columns = [
    {
      id: 1,
      label: 'Nome do Curso',
      value: 'name'
    },
    {
      id: 3,
      label: 'Ações',
      value: 'action'
    }
  ]

  async function handleAddCourse() {

    await database
      .collection('courses')
      .add({ name: nameOfCourse, id: uuidV4() })

    closeModal()
  }

  function closeModal() {
    setOpenModalNewCourse(false)
    setNameOfCourse('')
  }

  async function handleRemoveCourse(id: string) {
    if (window.confirm('Você tem certeza que deseja excluir esse curso ?')) {
      await database
        .collection('courses')
        .doc(id)
        .delete()
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography id="modal-modal-title" variant="h4" component="h1">
          Todos os cursos
        </Typography>
        <Button
          onClick={() => setOpenModalNewCourse(true)}
          variant="contained"
        >
          Cadastrar novo curso
        </Button>
      </div>

      {/* Modal Add course */}
      <Modal
        open={openModalNewCourse}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styleModal, width: isMobile() ? '80vw' : '30vw', }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={3}>
            Cadastre um novo curso
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="Nome do curso"
              value={nameOfCourse}
              onChange={text => setNameOfCourse(text.target.value)}
              variant="filled"
            />

            <Button
              onClick={handleAddCourse}
              variant="contained"
              sx={{ mt: 3 }}
            >
              Cadastrar curso
            </Button>
          </Box>
        </Box>
      </Modal>

      {
        courses.length === 0 ? (
          <Typography sx={{ mt: 4 }} variant="h5" component="h5">
            Não temos cursos cadastrados
          </Typography>
        ) : (
          <Paper sx={{ borderRadius: 2, p: 2, display: 'flex', flexDirection: 'column', mt: 3 }}>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) =>
                    <TableCell
                      align={index === 0 ? 'inherit' : 'right'}
                      key={column.id}
                    >
                      {column.label}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {courses.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">{row.name}</TableCell>
                    <TableCell align='right'>
                      <Button
                        onClick={() => handleRemoveCourse(row.firebaseId)}
                        variant="outlined"
                        size="small"
                        color="error"
                      >
                        Excluir
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )
      }

    </>
  )
}

export default Courses