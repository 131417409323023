const menu = [
  {
    id: 1,
    name: 'Gestão Acadêmica',
    children: [
      {
        name: 'Cursos',
        path: '/courses',
      },
      {
        name: 'Professores',
        path: '/teachers',
      },
      {
        name: 'Alunos',
        path: '/students',
      },
      {
        name: 'Calendário de aulas',
        path: '/calendar',
      },
    ],
    roles: ['ADMIN']
  },
  {
    id: 2,
    name: 'Gestão Financeira',
    children: [
      {
        name: 'Dashboard cursos',
        path: '/dashboard-courses',
      },
      {
        name: 'Dashboard alunos',
        path: '/dashboard-students',
      },
      {
        name: 'Dashboard professores',
        path: '/dashboard-teachers',
      },
      {
        name: 'Dashboard geral',
        path: '/dashboard',
      },
      {
        name: 'Despesas',
        path: '/expense',
      },
    ],
    roles: ['ADMIN']
  },
  {
    id: 3,
    name: 'Gestão Site',
    children: [
      {
        name: 'Depoimentos',
        path: '/testimony',
      },
      {
        name: 'Projetos',
        path: '/projects',
      },
      {
        name: 'Configurações',
        path: '/configs',
      }
    ],
    roles: ['ADMIN', 'MARKETING']
  },
  {
    id: 4,
    name: 'Gestão Site - OACS',
    children: [
      {
        name: 'Parceiros',
        path: '/oacs/partners',
      },
      {
        name: 'Links Live',
        path: '/oacs/live',
      },
      {
        name: 'Integrantes',
        path: '/oacs/members',
      },
      {
        name: 'Eventos',
        path: '/oacs/events',
      },
    ],
    roles: ['ADMIN', 'MARKETING']
  }
];

export { menu }