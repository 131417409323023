
import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  colors,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { v4 as uuidV4 } from 'uuid'
import { FilePond } from 'react-filepond'
import { toast } from 'react-toastify'

import { styleModal } from 'components/Modal/style'
import { database, storage, firebase } from 'config/firebase'
import { Student, useStudents } from 'hooks/useStudents'
import { useCourses } from 'hooks/useCourses'
import { Teacher, useTeachers } from 'hooks/useTeachers'
import { handleMonetaryMask, maskDate, maskOnlyNumbers } from 'utils/mask'

import { Timestamp } from "firebase/firestore";
import { getNextMonths, isMobile } from 'utils/functions'
import readFile from 'utils/storage'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

const Students = () => {
  const navigate = useNavigate()

  const [openModalNewStudent, setOpenModalNewStudent] = useState(false)
  const [name, setName] = useState('')
  const [course, setCourse] = useState('')
  const [amount, setAmount] = useState('')
  const [totalValue, setTotalValue] = useState('')
  const [teacher, setTeacher] = useState<Teacher | null>(null)
  const [email, setEmail] = useState('')
  const [registration, setRegistration] = useState(19000)
  const [loader, setLoader] = useState(false)
  const [uuid, setUuid] = useState('')
  const [firebaseId, setFirebaseId] = useState('')
  const [daysToExpire, setDaysToExpire] = useState()
  const [street, setStreet] = useState('')
  const [number, setNumber] = useState('')
  const [neighborhood, setNeighborhood] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [dob, setDob] = useState('')
  const [profession, setProfession] = useState('')
  const [maritalStatus, setMaritalStatus] = useState('')
  const [nationality, setNationality] = useState('')
  const [document, setDocument] = useState('')
  const [phone, setPhone] = useState('')
  const [classTime, setClassTime] = useState({
    weekday: '',
    hour: ''
  })

  const daysToExpireOptions = [
    {
      id: 90,
      label: '3 meses',
      month: 3
    },
    {
      id: 180,
      label: '6 meses',
      month: 6
    },
    {
      id: 365,
      label: '1 ano',
      month: 12
    },
  ]

  const [files, setFiles] = useState([]);
  const [contracts, setContracts] = useState<any[]>([]);
  const ref = useRef(null);

  const [studentsOutdatedContract, setStudentsOutdatedContract] = useState(0)
  const [modalStudentsOutdatedContract, setModalStudentsOutdatedContract] = useState(false)
  const [openModalRegularizeContract, setOpenModalRegularizeContract] = useState(false)

  const usStudentsContext = useStudents()
  const useCoursesState = useCourses()
  const useTeactherState = useTeachers()

  const courses = Object.values(useCoursesState)
  const students = Object.values(usStudentsContext)
  const teachers = Object.values(useTeactherState)

  const columns = [
    {
      id: 1,
      label: 'Nome Completo',
      value: 'name'
    },
    {
      id: 2,
      label: 'Curso',
      value: 'course'
    },
    {
      id: 3,
      label: 'Valor total da aula',
      value: 'amountMask'
    },
    {
      id: 4,
      label: 'Status',
      value: 'status'
    },
    {
      id: 5,
      label: 'Vencimento do contrato',
      value: 'contract'
    },
    {
      id: 6,
      label: 'Ações',
      value: 'action'
    }
  ]

  const Rows = students.map(s => {
    const [studentCourse] = courses.filter(item => item.id === s.course)

    return {
      ...s,
      nameOfCourse: studentCourse.name,
      status: s.status === 'active' ? 'Ativo' : 'Inativo',
      statusColor: s.status === 'active' ? colors.green[500] : colors.red[500],
    }
  })

  const calculateTotalValue = (e: string) => {
    if (teacher) {
      setAmount(e)
      setTotalValue((+maskOnlyNumbers(e) + +teacher.amount).toString())
    }
  }

  async function handleAddStudent() {
    if (!teacher) {
      toast.error('Para realizar o cadastro de um novo aluno é obrigatório selecionado um professor')
      return
    }

    if (contracts.length === 0) {
      toast.error('Para realizar o cadastro de um novo aluno é obrigatório ter pelo menos um contrato anexado')
      return
    }

    setLoader(true)

    await database
      .collection('students')
      .add({
        name,
        course,
        amount: totalValue,
        revenue: maskOnlyNumbers(amount),
        id: uuid,
        teacher: teacher.id,
        status: 'active',
        email,
        phone,
        registration,
        createdAt: Timestamp.fromDate(new Date()),
        contracts,
        daysToExpire,
        dob,
        profession,
        maritalStatus,
        nationality,
        document,
        address: {
          street,
          number,
          neighborhood,
          city,
          state
        }
      })

    const response = await database
      .collection('students')
      .where('id', '==', uuid)
      .get()

    const [student]: any = await response.docs.map((doc) => {
      return {
        ...doc.data(),
        firebaseId: doc.id,
      }
    })

    const [start] = classTime.hour.split('-')
    const monthSelected = daysToExpireOptions.find(d => d.id === daysToExpire)?.month || 3
    const dates = getNextMonths(start, classTime.weekday, monthSelected)

    for (const date of dates) {
      await database
        .collection('classes')
        .add({
          id: uuidV4(),
          date,
          month: moment(date, 'DD/MM/YYYY HH:mm').format('MM'),
          year: moment(date, 'DD/MM/YYYY HH:mm').format('YYYY'),
          amount: +totalValue / 4,
          teacherPayment: +teacher.amount / 4,
          teacher: teacher,
          teacherId: teacher.id,
          status: 1,
          studentId: uuid,
          student,
          course
        })
    }

    const availableTimes = teacher
      .availableTimes
      .map(({ weekday, hours }) => {
        let newHours = hours
        if (weekday === classTime.weekday) {
          newHours = newHours.filter(h => h !== classTime.hour)
        }

        return {
          weekday,
          hours: newHours
        }
      })

    await database
      .collection('teachers')
      .doc(teacher.firebaseId)
      .update({
        ...teacher,
        availableTimes
      })

    closeModal()
  }

  function closeModal() {
    setOpenModalNewStudent(false)
    setName('')
    setCourse('')
    setAmount('')
    setTeacher(null)
    setEmail('')
    setRegistration(19000)
    setFiles([])
    setContracts([])
    setClassTime({
      hour: '',
      weekday: ''
    })
    setLoader(false)
  }

  const handleRegisterNewStudent = () => {
    if (courses.length === 0 && teachers.length === 0) {
      toast.error('Para realizar o cadastro de um novo aluno é obrigatório ter pelo menos um curso e um professor cadastrado')
      return
    }

    if (courses.length === 0) {
      toast.error('Para realizar o cadastro de um novo aluno é obrigatório ter pelo menos um curso cadastrado')
      return
    }

    if (teachers.length === 0) {
      toast.error('Para realizar o cadastro de um novo aluno é obrigatório ter pelo menos um professor cadastrado')
      return
    }

    setUuid(uuidV4())
    setOpenModalNewStudent(true)
  }

  async function handleGetURL(metadata: firebase.storage.FullMetadata) {
    const pathDownload = await readFile(`/${metadata.fullPath}`)

    setContracts([{
      local: metadata.fullPath,
      src: pathDownload,
      createdAt: moment().format('DD/MM/YYYY'),
      expire: moment().add(daysToExpire, 'day').format('DD/MM/YYYY')
    }, ...contracts])

    setLoader(false)
  }

  const handleOutdatedContract = (row: Student) => {
    setName(row.name)
    setUuid(row.id)
    setFirebaseId(row.firebaseId)
    setContracts(row.contracts)
    setOpenModalRegularizeContract(true)
  }

  const handleRegularizeContract = async () => {
    await database
      .collection('students')
      .doc(firebaseId)
      .update({
        contracts
      })

    setOpenModalRegularizeContract(false)
    closeModal()
    toast.success('Contrato atualizado com sucesso')
  }

  const changeStatusStudent = async (row: Student) => {
    await database
      .collection('students')
      .doc(row.firebaseId)
      .update({
        status: row.status === 'Ativo' ? 'disabled' : 'active'
      })

    toast.success('Aluno atualizado')
  }

  const handleClassTime = (value: string) => {
    setClassTime({
      ...classTime,
      hour: value
    })
  }

  useEffect(() => {
    const outdatedContract = students.filter(student => student.outdatedContract)

    if (outdatedContract.length > 0) {
      setStudentsOutdatedContract(outdatedContract.length)
      setModalStudentsOutdatedContract(true)
    }
  }, [])

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography id="modal-modal-title" variant="h4" component="h1">
          Todos os alunos
        </Typography>
        <Button
          onClick={handleRegisterNewStudent}
          variant="contained"
        >
          Cadastrar novo aluno
        </Button>
      </div>

      {/* Alert Student */}
      <Modal
        open={modalStudentsOutdatedContract}
        onClose={() => setModalStudentsOutdatedContract(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styleModal, width: '30vw', border: '10px solid #CA9F6A' }}>
          <Typography color="secondary" fontWeight={700} id="modal-modal-title" variant="h5" component="h1" mb={3}>
            Atenção!!
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h5" mb={3}>
            Ao buscar os alunos, notamos que temos <b style={{ color: '#f44336' }}>{studentsOutdatedContract} aluno(s) com o contrato defasado</b>, por gentileza atualize o contrato desse aluno.
          </Typography>

          <Button onClick={() => setModalStudentsOutdatedContract(false)}>
            Fechar
          </Button>
        </Box>
      </Modal>

      {/* Modal regularize contract */}
      <Modal
        open={openModalRegularizeContract}
        onClose={() => setOpenModalRegularizeContract(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styleModal, width: isMobile() ? '100vw' : '50vw', }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={3}>
            Regularizar o contrato do {name}
          </Typography>

          <Grid container spacing={1} >

            <Grid item xs={12}>
              <FilePond
                files={files}
                ref={ref}
                allowMultiple={false}
                name="files"
                labelIdle="Arraste o novo contrato ou clique para selecionar!"
                acceptedFileTypes={['pdf']}
                server={{
                  process: (
                    _fieldName: any,
                    file: any,
                    _metadata: any,
                    load: (arg0: any) => void,
                    error: (arg0: any) => void,
                    progress: (arg0: boolean, arg1: any, arg2: any) => void,
                    _abort: any
                  ) => {
                    const score = storage.ref().child(`academia-symphonia/contracts/${uuid}/${file.name}`).put(file, {
                      contentType: file.type
                    });
                    score.on(
                      firebase.storage.TaskState.RUNNING,
                      (snap: any) => {
                        progress(true, snap.bytesTransferred, snap.totalBytes);
                      },
                      (err: any) => {
                        error(err.message);
                      },
                      () => {
                        handleGetURL(score.snapshot.metadata)
                        load(file.name)
                      }
                    );
                    score.on(
                      firebase.storage.TaskState.SUCCESS,
                      (snap: any) => {

                      },
                      (err: any) => {
                        error(err.message);
                      },
                      () => {
                        handleGetURL(score.snapshot.metadata)
                        load(file.name)
                      }
                    );
                  },
                }}
                onupdatefiles={(fileItems: any) => {
                  setFiles(fileItems.map((fileItem: any) => fileItem.file) as any)
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                onClick={handleRegularizeContract}
                variant="contained"
                sx={{ mt: 2 }}
                fullWidth
              >
                Atualizar contrato
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* Modal Add student */}
      <Modal
        open={openModalNewStudent}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styleModal, width: isMobile() ? '80vw' : '70vw', maxHeight: 800, }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={4}>
            Cadastre um novo aluno
          </Typography>

          <Grid container spacing={3} >
            <Grid item xs={12}>
              <Typography id="modal-modal-title" variant="body1" component="h2">
                Dados pessoais
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Nome do aluno(a)"
                value={name}
                onChange={text => setName(text.target.value)}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="E-mail para contato"
                value={email}
                onChange={text => setEmail(text.target.value)}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Nacionalidade"
                value={nationality}
                onChange={text => setNationality(text.target.value)}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Data de nascimento"
                value={maskDate(dob)}
                onChange={text => setDob(text.target.value)}
                variant="filled"
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Documento"
                value={document}
                onChange={text => setDocument(text.target.value)}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Estado Cívil</InputLabel>
                <Select
                  value={maritalStatus}
                  onChange={(text: any) => setMaritalStatus(text.target.value)}
                >
                  <MenuItem value="Solteiro">
                    Solteiro
                  </MenuItem>
                  <MenuItem value="Casado">
                    Casado
                  </MenuItem>
                  <MenuItem value="Divorciado">
                    Divorciado
                  </MenuItem>
                  <MenuItem value="Viúvo">
                    Viúvo
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography id="modal-modal-title" variant="body1" component="h2">
                Dados de endereço
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                label="Logradouro"
                value={street}
                onChange={text => setStreet(text.target.value)}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Número"
                value={number}
                onChange={text => setNumber(text.target.value)}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Bairro"
                value={neighborhood}
                onChange={text => setNeighborhood(text.target.value)}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Estado"
                value={state}
                onChange={text => setState(text.target.value)}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Cidade"
                value={city}
                onChange={text => setCity(text.target.value)}
                variant="filled"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography id="modal-modal-title" variant="body1" component="h2">
                Dados Acadêmicos
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Curso</InputLabel>
                <Select
                  value={course}
                  onChange={text => setCourse(text.target.value)}
                >
                  {
                    courses.map((course: any) => <MenuItem key={course.id} value={course.id}>{course.name}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Professor</InputLabel>
                <Select
                  value={teacher}
                  onChange={(text: any) => setTeacher(text.target.value)}
                  disabled={!course}
                >
                  {
                    teachers
                      .filter(currentTeacher => currentTeacher.status === 'active')
                      .filter(currentTeacher => currentTeacher.courses.includes(course))
                      .map((currentTeacher: any) =>
                        <MenuItem
                          key={currentTeacher.id}
                          value={currentTeacher}
                        >
                          {currentTeacher.name}
                        </MenuItem>
                      )
                  }
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Valor do professor/custo"
                value={teacher?.amountMask}
                defaultValue="R$ 0.00"
                variant="filled"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Valor aluno/parcial"
                value={handleMonetaryMask(amount)}
                onChange={text => calculateTotalValue(text.target.value)}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Valor total da aula"
                value={handleMonetaryMask(totalValue)}
                defaultValue="R$ 0.00"
                variant="filled"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Valor matrícula"
                value={handleMonetaryMask(registration.toString())}
                onChange={text => setRegistration(+text.target.value)}
                variant="filled"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Dia da semana</InputLabel>
                <Select
                  value={classTime.weekday}
                  onChange={(text: any) => setClassTime({ weekday: text.target.value, hour: '' })}
                  disabled={!teacher}
                >
                  {
                    teachers
                      .find(currentTeacher => currentTeacher.id === teacher?.id)
                      ?.availableTimes
                      .map((time: any) =>
                        <MenuItem
                          key={time.weekday}
                          value={time.weekday}
                        >
                          {time.weekday}
                        </MenuItem>
                      )
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Horário</InputLabel>
                <Select
                  value={classTime.hour}
                  onChange={({ target: { value } }: any) => handleClassTime(value)}
                  disabled={!teacher}
                >
                  {
                    teachers
                      .find(currentTeacher => currentTeacher.id === teacher?.id)
                      ?.availableTimes
                      .find(week => week.weekday === classTime.weekday)
                      ?.hours
                      .map((time: any) => {
                        return (
                          <MenuItem
                            key={time}
                            value={time}
                          >
                            {time}
                          </MenuItem>
                        )
                      })
                  }
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Vencimento do contato</InputLabel>
                <Select
                  value={daysToExpire}
                  onChange={({ target: { value } }: any) => setDaysToExpire(value)}
                >
                  {
                    daysToExpireOptions
                      .map((time: any) => {
                        return (
                          <MenuItem
                            key={time.id}
                            value={time.id}
                          >
                            {time.label}
                          </MenuItem>
                        )
                      })
                  }
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FilePond
                files={files}
                ref={ref}
                allowMultiple={false}
                name="files"
                labelIdle="Arraste o contrato ou clique para selecionar!"
                acceptedFileTypes={['pdf']}
                server={{
                  process: (
                    _fieldName: any,
                    file: any,
                    _metadata: any,
                    load: (arg0: any) => void,
                    error: (arg0: any) => void,
                    progress: (arg0: boolean, arg1: any, arg2: any) => void,
                    _abort: any
                  ) => {
                    setLoader(true)
                    const score = storage.ref().child(`academia-symphonia/contracts/${uuid}/${file.name}`).put(file, {
                      contentType: file.type
                    });
                    score.on(
                      firebase.storage.TaskState.RUNNING,
                      (snap: any) => {
                        progress(true, snap.bytesTransferred, snap.totalBytes);
                      },
                      (err: any) => {
                        error(err.message);
                      },
                      () => {
                        handleGetURL(score.snapshot.metadata)
                        load(file.name)
                      }
                    );
                    score.on(
                      firebase.storage.TaskState.SUCCESS,
                      (snap: any) => {

                      },
                      (err: any) => {
                        error(err.message);
                      },
                      () => {
                        handleGetURL(score.snapshot.metadata)
                        load(file.name)
                      }
                    );
                  },
                }}
                onupdatefiles={(fileItems: any) => {
                  setFiles(fileItems.map((fileItem: any) => fileItem.file) as any)
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                onClick={handleAddStudent}
                variant="contained"
                sx={{ mt: 2 }}
                fullWidth
                disabled={loader}
              >
                {loader ? 'Cadastrando aluno...' : 'Cadastrar aluno'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {
        students.length === 0 ? (
          <Typography sx={{ mt: 4 }} variant="h5" component="h5">
            Não temos alunos cadastrados
          </Typography>
        ) : (
          <Paper sx={{ borderRadius: 2, p: 2, display: 'flex', flexDirection: 'column', mt: 3 }}>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) =>
                    <TableCell
                      align={index === 0 ? 'inherit' : 'right'}
                      key={column.id}
                    >
                      {column.label}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {Rows.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">{row.name}</TableCell>
                    <TableCell align="right">{row.nameOfCourse}</TableCell>
                    <TableCell align="right">{row.amountMask}</TableCell>
                    <TableCell align="right" sx={{ color: row.statusColor }}>{row.status}</TableCell>
                    <TableCell align="right">{row.contracts.find(c => moment().isAfter(c.createdAt))?.expire}</TableCell>
                    <TableCell align="right">
                      <Button
                        variant="outlined"
                        size="small"
                        color={row.status === 'Ativo' ? "warning" : 'success'}
                        onClick={() => changeStatusStudent(row)}
                        sx={{ mx: 1 }}
                      >
                        {row.status === 'Ativo' ? 'Desativar' : 'Ativar'}
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        color="inherit"
                        onClick={() => navigate(row.firebaseId)}
                        sx={{ mx: 1 }}
                      >
                        Detalhes
                      </Button>
                      {
                        row.outdatedContract && (
                          <Button
                            variant="contained"
                            size="small"
                            color="error"
                            onClick={() => handleOutdatedContract(row)}
                            sx={{ mx: 1 }}
                          >
                            Regularizar contrato
                          </Button>
                        )
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )
      }

    </>
  )
}

export default Students
