import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { colors, Paper, Typography } from '@mui/material';

type BarChartProps = {
  title: string;
  datasets: any;
  labels?: string[]
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  legend: {
    display: false,
  },
  title: {
    display: true,
    text: ''
  },
}

export const dataset = {
  maxBarThickness: 100,
  backgroundColor: [
    colors.blue[100],
    colors.green[100],
    colors.yellow[100],
    colors.orange[100],
    colors.purple[100],
    colors.pink[100],
    colors.red[100],
  ],
  borderColor: [
    colors.blue[900],
    colors.green[900],
    colors.yellow[900],
    colors.orange[900],
    colors.purple[900],
    colors.pink[900],
    colors.red[900],
  ],
  borderWidth: 2
}

export function BarChart({ title, labels, datasets }: BarChartProps) {

  const data = {
    labels,
    datasets: [{
      ...dataset,
      label: title,
      data: datasets
    }]
  }

  return (
    <Paper sx={{ p: 2, borderRadius: 2 }}>
      <Typography ml={1} mb={3} fontWeight={700} component="h2" variant="h5" color="primary" gutterBottom>
        {title}
      </Typography>
      <Bar options={options} data={data} />
    </Paper>
  )
}

export function PieChart({ title, labels, datasets }: BarChartProps) {

  const options = {
    responsive: true,
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: ''
    },
  }

  const dataset = {
    maxBarThickness: 100,
    backgroundColor: [
      colors.blue[100],
      colors.green[100],
      colors.yellow[100],
      colors.orange[100],
      colors.purple[100],
      colors.pink[100],
      colors.red[100],
    ],
    borderColor: [
      colors.blue[900],
      colors.green[900],
      colors.yellow[900],
      colors.orange[900],
      colors.purple[900],
      colors.pink[900],
      colors.red[900],
    ],
    borderWidth: 2
  }

  const data = {
    labels,
    datasets: [{
      ...dataset,
      data: datasets
    }]
  }

  return (
    <>
      <Typography ml={1} mb={3} fontWeight={700} component="h2" variant="h5" color="primary" gutterBottom>
        {title}
      </Typography>
      <Pie options={options} data={data} />
    </>
  )
}