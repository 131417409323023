import React, { createContext, ReactNode } from 'react';

import { auth, firebase } from 'config/firebase';
import { useUserData } from 'hooks/useUserData';
import { users } from 'enum/auth';
import { menu } from 'enum/menus';
import { useMenu } from 'hooks/useMenu';

type AuthContextTypes = {
  signInWithGoogle: () => Promise<any>
}

type AuthContextProviderProps = {
  children: ReactNode
}

export const AuthContext = createContext({} as AuthContextTypes)

export function AuthContextProvider(props: AuthContextProviderProps) {

  async function signInWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider()
    const response = await auth.signInWithPopup(provider)
    const currentUser = users.find(user => user.email === response.user?.email)

    if (typeof currentUser === 'undefined') {
      await firebase.auth().currentUser?.delete()
      return false
    }

    validateUser(response, currentUser.role)
    const [m] = menu.filter(m => m.roles.includes(currentUser.role as string))
    useMenu.setState({
      path: m.children[0].path
    })
    return m
  }

  function validateUser(response: any, role: string) {
    if (response) {
      const { displayName, uid, email } = response.user
      const { accessToken } = response.credential
      
      useUserData.setState({
        email,
        name: displayName,
        logged: true,
        id: uid,
        role,
        accessToken
      })
    }
  }

  return (
    <AuthContext.Provider value={{ signInWithGoogle }}>
      {props.children}
    </AuthContext.Provider>
  )
}
