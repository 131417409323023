
export const billsDescriptions = [
  {
    name: 'Energia',
    id: 1
  },
  {
    name: 'Água',
    id: 2
  },
  {
    name: 'Aluguel',
    id: 3
  },
  {
    name: 'Gastos esporádicos',
    id: 4
  },
  {
    name: 'Outros',
    id: 5
  },
]