import moment from "moment";

export const convertTimestamp = (timestamp: any): Date => {
  const date = timestamp.toDate();
  const mm = date.getMonth();
  const dd = date.getDate();
  const yyyy = date.getFullYear();
  const HH = date.getHours()
  const m = date.getMinutes()
  const ss = date.getSeconds()
  return new Date(yyyy, mm, dd, HH, m, ss)
}

export const sortByLargestDate = (arr: any[]) => {
  return arr.sort((a, b) => b.date.getTime() - a.date.getTime())
}

export const isMobile = () => document.body.clientWidth <= 375

export const checkDateIsAfter = (createdAt: Date, day: number) => {
  const now = new Date()
  const past = createdAt
  const diff = Math.abs(now.getTime() - past.getTime())
  const days = Math.ceil(diff / (1000 * 60 * 60 * 24))

  return day < days
}

export const getHoursArray = (startTime: Date, endTime: Date) => {
  const start = moment(startTime, 'HH:mm');
  let end = moment(endTime, 'HH:mm');
  let duration = moment.duration(end.diff(start));
  let hours = [start.format('HH:mm')];
  for (let i = 1; i < duration.asHours(); i++) {
    hours.push(start.add(1, 'hours').format('HH:mm') as any);
  }
  return hours;
}

export function getNextMonths(startTime: string, weekday: string, month: number) {
  const dayOfWeek = getDayOfWeek(weekday)
  const [hour, minutes] = startTime.split(':')
  const today = moment()
  const nextWeekday = moment(today).day(dayOfWeek).add(7, "days");

  const mondayDates = [];
  const currentDate = moment(nextWeekday).startOf('week');

  while (currentDate.isBefore(moment(nextWeekday).add(month, 'months'))) {

    if (currentDate.day() === dayOfWeek) {
      const day = moment(currentDate).hour(+hour).minutes(+minutes).format('DD/MM/YYYY HH:mm')
      mondayDates.push(day);
    }

    currentDate.add(1, 'days');
  }


  return mondayDates;
}

function getDayOfWeek(dayInPortuguese: any) {

  const days: any = {
    "Domingo": 0,
    "Segunda-feira": 1,
    "Terça-feira": 2,
    "Quarta-feira": 3,
    "Quinta-feira": 4,
    "Sexta-feira": 5,
    "Sábado": 6,
  };

  return days[dayInPortuguese];
}
