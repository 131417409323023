
import React, { useEffect, useRef, useState } from 'react'
import {
  Badge,
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'

import {
  DeleteForeverRounded
} from '@mui/icons-material'

import { styleModal } from 'components/Modal/style'
import { database, storage, firebase } from 'config/firebase'
import { toast } from 'react-toastify'
import { isMobile } from 'utils/functions'
import { FilePond } from 'react-filepond'
import readFile from 'utils/storage';
import JoditEditor from 'jodit-react';
import { v4 as uuid } from 'uuid'

type Event = {
  firebaseId: string;
  title: string;
  date: string;
  description: string;
  banner: {
    local: string,
    src: string
  };
}

const Events = () => {
  const [openModalNewEvent, setOpenModalNewEvent] = useState(false)
  const [events, setEvents] = useState<Event[]>([])
  const [title, setTitle] = useState('')
  const [dateOfEvent, setDateOfEvent] = useState('')
  const [description, setDescription] = useState('');
  const [banner, setBanner] = useState({
    local: '',
    src: ''
  });
  const [id, setId] = useState('');

  const [files, setFiles] = useState([]);
  const [filesImages, setFilesImages] = useState([]);
  const [images, setImages] = useState<any>([]);
  const ref = useRef(null);

  const columns = [
    {
      id: 1,
      label: 'Banner',
      value: 'banner'
    },
    {
      id: 2,
      label: 'Título',
      value: 'title'
    },
    {
      id: 3,
      label: 'Data do evento',
      value: 'date'
    },
    {
      id: 4,
      label: 'Ação',
      value: ''
    },
  ]

  async function handleAddEvent() {

    if (id) {
      await database
        .collection('oacs-events')
        .doc(id)
        .update({
          title,
          description,
          date: dateOfEvent,
          banner,
          images
        })
      toast.success('Evento editado com sucesso')
      return closeModal()
    }
    const registerData = {
      uuid: uuid(),
      title,
      description,
      date: dateOfEvent,
      banner,
      images
    }

    await database
      .collection('oacs-events')
      .add(registerData)

    toast.success('Evento criado com sucesso')
    closeModal()
  }

  async function getEvents() {
    await database
      .collection('oacs-events')
      .onSnapshot(query => {
        const data = query.docs.map((doc) => {
          return {
            ...doc.data(),
            firebaseId: doc.id,
          }
        }) as Event[]

        setEvents(data)
      })
  }

  function closeModal() {
    setOpenModalNewEvent(false)
    setTitle('')
    setDescription('')
    setBanner({
      local: '',
      src: ''
    })
    setFiles([])
    setFilesImages([])
    setImages([])
    setDateOfEvent('')
    setId('')
  }

  function editEvent({ title, date, description, banner, firebaseId, images: imgs }: any) {
    setOpenModalNewEvent(true)
    setTitle(title)
    setDateOfEvent(date)
    setDescription(description)
    setBanner(banner)
    setId(firebaseId)
    setImages(imgs)
  }

  async function deleteEvent(firebaseId: string) {
    if (window.confirm('Você tem certeza que deseja excluir esse evento ?')) {
      const event = events.find(x => x.firebaseId === firebaseId)
      await database
        .collection('oacs-events')
        .doc(firebaseId)
        .delete()
      await deleteBanner(event?.banner.local as string)

      toast.success('Evento excluido com sucesso!')
    }
  }

  async function handleGetURL(metadata: firebase.storage.FullMetadata) {
    const pathDownload = await readFile(`/${metadata.fullPath}`)

    setBanner({
      local: metadata.fullPath,
      src: pathDownload
    })
  }

  async function handleGetURLByImages(metadata: firebase.storage.FullMetadata) {
    const pathDownload = await readFile(`/${metadata.fullPath}`)

    setImages([...images, {
      local: metadata.fullPath,
      src: pathDownload
    }])
    setFilesImages([])
  }

  async function deleteBanner(local: string) {
    await storage.ref().child(local).delete()
    setBanner({
      local: '',
      src: ''
    })
    setFiles([])
  }

  async function deleteImage(local: string) {
    await storage.ref().child(local).delete()
    const newImages = images.filter((x: any) => x.local !== local)
    setFilesImages(newImages)
  }

  useEffect(() => {
    getEvents()
  }, [])

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography id="modal-modal-title" variant="h4" component="h1">
          Todos os eventos
        </Typography>
        <Button
          onClick={() => setOpenModalNewEvent(true)}
          variant="contained"
        >
          Cadastrar novo evento
        </Button>
      </div>

      {/* Modal Add testmonies */}
      <Modal
        open={openModalNewEvent}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styleModal, width: isMobile() ? '80vw' : '50vw', maxHeight: 800 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={3}>
            Cadastre um novo depoimento
          </Typography>

          <Grid container spacing={1} >
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Título do evento"
                value={title}
                onChange={text => setTitle(text.target.value)}
                variant="filled"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Data do evento"
                value={dateOfEvent}
                onChange={text => setDateOfEvent(text.target.value)}
                variant="filled"
              />
            </Grid>

            <Grid item xs={12} md={12} mb={4} mt={4}>
              {
                banner.src.length > 0
                  ? (
                    <Badge>
                      <DeleteForeverRounded color="error" onClick={() => deleteBanner(banner.local)} />
                      <img alt='banner' src={banner.src} width={450} />
                    </Badge>
                  )
                  : (
                    <FilePond
                      files={files}
                      ref={ref}
                      allowMultiple={false}
                      name="files"
                      labelIdle="Arraste um banner ou clique para selecionar!"
                      server={{
                        process: (
                          _fieldName: any,
                          file: any,
                          _metadata: any,
                          load: (arg0: any) => void,
                          error: (arg0: any) => void,
                          progress: (arg0: boolean, arg1: any, arg2: any) => void,
                          _abort: any
                        ) => {
                          const score = storage.ref().child(`oacs-events/${file.name}`).put(file, {
                            contentType: file.type
                          });
                          score.on(
                            firebase.storage.TaskState.RUNNING,
                            (snap: any) => {
                              progress(true, snap.bytesTransferred, snap.totalBytes);
                            },
                            (err: any) => {
                              error(err.message);
                            },
                            () => {
                              handleGetURL(score.snapshot.metadata)
                              load(file.name)
                            }
                          );
                          score.on(
                            firebase.storage.TaskState.SUCCESS,
                            (snap: any) => {

                            },
                            (err: any) => {
                              error(err.message);
                            },
                            () => {
                              handleGetURL(score.snapshot.metadata)
                              load(file.name)
                            }
                          );
                        },
                      }}
                      onupdatefiles={(fileItems: any) => {
                        setFiles(fileItems.map((fileItem: any) => fileItem.file) as any)
                      }}
                    />
                  )
              }
            </Grid>

            <Grid item xs={12} md={12}>
              <JoditEditor
                value={description}
                onChange={(newContent) => setDescription(newContent)}
              />
            </Grid>

            <Grid item md={12} mt={4} mb={4}>
              <FilePond
                files={filesImages}
                ref={ref}
                allowMultiple={true}
                name="files"
                labelIdle="Arraste as imagens do evento ou clique para selecionar!"
                server={{
                  process: (
                    _fieldName: any,
                    file: any,
                    _metadata: any,
                    load: (arg0: any) => void,
                    error: (arg0: any) => void,
                    progress: (arg0: boolean, arg1: any, arg2: any) => void,
                    _abort: any
                  ) => {
                    const score = storage.ref().child(`oacs-events/${title}/${file.name}`).put(file, {
                      contentType: file.type
                    });
                    score.on(
                      firebase.storage.TaskState.RUNNING,
                      (snap: any) => {
                        progress(true, snap.bytesTransferred, snap.totalBytes);
                      },
                      (err: any) => {
                        error(err.message);
                      },
                      () => {
                        handleGetURLByImages(score.snapshot.metadata)
                        load(file.name)
                      }
                    );
                    score.on(
                      firebase.storage.TaskState.SUCCESS,
                      (snap: any) => {

                      },
                      (err: any) => {
                        error(err.message);
                      },
                      () => {
                        handleGetURLByImages(score.snapshot.metadata)
                        load(file.name)
                      }
                    );
                  },
                }}
                onupdatefiles={(fileItems: any) => {
                  setFilesImages(fileItems.map((fileItem: any) => fileItem.file) as any)
                }}
              />

              <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                {
                  images.map((img: any) => (
                    <Badge>
                      <DeleteForeverRounded color="error" onClick={() => deleteImage(img.local)} />
                      <img alt='banner' src={img.src} width={200} />
                    </Badge>
                  ))
                }
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Button
                onClick={handleAddEvent}
                variant="contained"
                sx={{ mt: 2 }}
                fullWidth
              >
                Cadastrar evento
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {
        events.length === 0 ? (
          <Typography sx={{ mt: 4 }} variant="h5" component="h5">
            Não temos eventos cadastrados
          </Typography>
        ) : (
          <Paper sx={{ borderRadius: 2, p: 2, display: 'flex', flexDirection: 'column', mt: 3 }}>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  {columns.map((column) => <TableCell key={column.id}>{column.label}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map((row) => (
                  <TableRow key={row.firebaseId}>
                    <TableCell>
                      <img width={350} height={200} src={row.banner.src} alt="banner" />
                    </TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        size="small"
                        color="info"
                        onClick={() => editEvent(row)}
                      >
                        Editar
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        color="error"
                        sx={{ ml: 1 }}
                        onClick={() => deleteEvent(row.firebaseId)}
                      >
                        Excluir
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )
      }

    </>
  )
}

export default Events
