import React from 'react';
import {
  Box,
  Typography,
  Container
} from '@mui/material';

export default function NotFound() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container component="main" maxWidth="md">
        <Typography variant="h4" component="h1">
          Página não encontrada, por favor entre em contato com o administrador do sistema.
        </Typography>
      </Container>
    </Box>
  );
}