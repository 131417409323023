import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Grid, TextField, Typography } from '@mui/material'

import { database } from 'config/firebase'
import { useConfigs } from 'hooks/useConfigs'
import { maskOnlyNumbers, maskPhoneNumber } from 'utils/mask'

const Configs = () => {

  const configs = useConfigs()

  const [instagram, setInstagram] = useState(configs.instagram)
  const [facebook, setFacebook] = useState(configs.facebook)
  const [phone, setPhone] = useState(configs.phone)
  const [maps, setMaps] = useState(configs.maps)
  const [email, setEmail] = useState(configs.email)
  const [loading, setLoading] = useState(false)

  const handleDisableButton = instagram === configs.instagram && 
  facebook === configs.facebook && 
  phone === configs.phone &&
  maps === configs.maps &&
  email === configs.email

  async function handleAddConfigs() {
    setLoading(true)

    await database
      .collection('configurations')
      .add({
        instagram,
        facebook,
        maps,
        phone: maskOnlyNumbers(phone),
        email
      })

    toast.success('Configurações cadastrada com sucesso')
    setLoading(false)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} >
        <Typography id="modal-modal-title" variant="h4" component="h1">
          Configurações de contato
        </Typography>
      </Grid>

      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          label="URL Instagram"
          value={instagram}
          onChange={text => setInstagram(text.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          label="URL Facebook"
          value={facebook}
          onChange={text => setFacebook(text.target.value)}
          variant="filled"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          label="Celular/WhatsApp"
          value={maskPhoneNumber(phone)}
          onChange={text => setPhone(text.target.value)}
          variant="filled"
          fullWidth
          inputProps={{
            maxLength: 15
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          label="E-mail"
          value={email}
          onChange={text => setEmail(text.target.value)}
          variant="filled"
          fullWidth
        />
      </Grid>

      <Grid item mt={3} xs={12}>
        <Typography id="modal-modal-title" variant="h4" component="h1">
          Configurações de localização
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Link do google maps"
          value={maps}
          onChange={text => setMaps(text.target.value)}
          variant="filled"
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          onClick={handleAddConfigs}
          variant="contained"
          sx={{ mt: 3 }}
          disabled={loading || handleDisableButton}
        >
          Cadastrar configurações
        </Button>
      </Grid>
    </Grid>
  )
}

export default Configs