import create from 'zustand'
import { persist } from 'zustand/middleware'

type Menu = {
  name?: string,
  path?: string,
  spacer?: boolean,
}

export const useMenu = create<Menu>(
  persist(
    () => ({ }), { name: 'useMenu' }
  )
)
