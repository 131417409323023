export const styleModal = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50vw',
  maxHeight: 500,
  overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 8,
  p: 4,
  borderRadius: 2
};
