import { Box, Grid, TextField, Typography } from '@mui/material'

import iconPDF from 'assets/pdf.png'
import { useStudents } from 'hooks/useStudents'
import { useTeachers } from 'hooks/useTeachers'
import moment from 'moment'
import { convertTimestamp } from 'utils/functions'

const StudentDetails = () => {
  const studentId = window.location.pathname.split('/')[2]

  const useStudentsContext = useStudents()
  const useTeachersContext = useTeachers()

  const currentStudent = Object
    .values(useStudentsContext)
    .find(s => s.firebaseId === studentId)

  const teacher = Object
    .values(useTeachersContext)
    .find(t => t.id === currentStudent?.teacher)

  console.log(currentStudent)

  return (
    <>
      <h2>Detalhes do aluno</h2>
      <Grid container spacing={1} mb={4} >
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Nome do aluno(a)"
            value={currentStudent?.name}
            // onChange={text => setName(text.target.value)}
            variant="filled"
            disabled
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="E-mail do aluno(a)"
            value={currentStudent?.email}
            // onChange={text => setName(text.target.value)}
            variant="filled"
            disabled
          />
        </Grid>
      </Grid>
      <h2>Professor</h2>
      <Grid container spacing={1} mb={4}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Nome do aluno(a)"
            value={teacher?.name}
            // onChange={text => setName(text.target.value)}
            variant="filled"
          />
        </Grid>
      </Grid>
      <h2>Contratos</h2>
      <Grid container spacing={1}>
        {
          currentStudent?.contracts.map(c => (
            <Grid item xs={12} md={1}>
              <Box style={{ cursor: 'pointer' }} display="flex" flexDirection="column" alignItems="center" onClick={() => window.open(c.src, '_blank')}>
                <img style={{ width: '100%' }} src={iconPDF} />
                <Typography mt={1} variant="subtitle2">{moment(c.createdAt).format('DD/MM/YYYY')}</Typography>
              </Box>
            </Grid>
          ))
        }
      </Grid>
    </>
  )
}

export default StudentDetails